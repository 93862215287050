'use strict';

/* exported
purchaseTicketsCtrl
 */

function purchaseTicketsCtrl($scope, $rootScope, InventoryRestangular, Restangular, appConfig) {

    function handleSuccess(message) {
        console.log('success: ' + message);
        $rootScope.$emit('notify', {type: 'success', title: 'Success', message: message});
    }

    function handleError(err) {
        var message = err.status ? ''+ err.status + ' ' : '';
        if (_.isArray(err)) {
            message += err.join('<br>');
        } else {
            message += _.get(err, 'data.message') || err.message || '';
        }
        console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
        if(message !== 'Closed Modal') {
            $rootScope.$emit('notify', {type: 'error', title: 'Error', message: message});
        }
    }

    function fetchLine(lineId, fromStation){
        const getObject = {};
        if (fromStation){
            getObject.fromStation = fromStation;
        }
        InventoryRestangular.one('routes', lineId).get(getObject)
            .then(function(line){
                $scope.line = line;
            })
    }

    $scope.init = function(lineId = '60eb08913811906e980cd328', fromStation = null){
        $scope.line = null;
        $scope.form = {
            selectedTrip: null,
            email: null,
            numberOfTickets: 1,
        };
        $scope.cartPayLink = null;
        $scope.totalCreatedBooking = null;
        $scope.locked = false;
        fetchLine(lineId, fromStation);
    };

    function isFormValid(form){
        return form.email && form.numberOfTickets && form.selectedTrip && form.selectedTrip.id;
    }

    $scope.submit = function(){
        if ($scope.locked){
            return;
        }
        if (!isFormValid($scope.form)){
            $rootScope.$emit('notify', {type: 'error', title: 'Error', message: `Errors in input`});
            return;
        }
        $scope.cartPayLink = null;
        $scope.locked = true;
        const user = window.user;
        Restangular.all('v2/bookings/bulk-create-bookings')
            .customPOST({
                tripId: $scope.form.selectedTrip.id,
                email: $scope.form.email,
                numberOfTickets: $scope.form.numberOfTickets,
                visitorId: user._id,
            }, undefined, undefined, {"X-Distribution-Channel": 'transfer-holbox'})
            .then(function (cart) {
                $scope.totalCreatedBooking = $scope.form.numberOfTickets;
                $scope.cartPayLink = appConfig.frontendBrowserUrl + '/checkout/cart/' + cart + '/payment';
                handleSuccess('created');
            })
            .catch(handleError)
            .finally(function(){
                $scope.locked = false;
            })
    };
    $scope.init();
    $scope.switchDirection = function () {
        if ($scope.locked){
            return;
        }
        $scope.init($scope.line._id, $scope.line.to._id);
    };
}
